<template>
  <div class="about">
    <b-img
      class="header-img mb-4"
      src="/assets/about/wigand-heppelmann.jpg"
      fluid
      rounded="bottom"
      alt="Brigitte Wigand-Heppelmann und Hans Eberhard Heppelmann"
    ></b-img>
    <h1>Das sind wir</h1>
    <div class="description mb-5 text-left text-container">
      <p>
        Wir beraten Sie bei der Gründung, Weiterentwicklung und Veräußerung
        Ihrer Apotheke. Profitieren Sie von unserem Wissensvorsprung und unseren
        Partnerschaften. Zudem bieten wir Ihnen die Möglichkeit an unseren
        <a href="/erfa">Erfa Tagungen</a> teilzunehmen und sich hier im
        kollegialen Austausch mit neuen Ideen aufzuladen.
      </p>
      <p>
        Durch unsere jahrzehntelange Selbständigkeit und Erfahrung in Apotheken
        und Großhandel verfügen wir über ein großes Netzwerk, das wir zu Ihrem
        Vorteil einsetzen.
      </p>
      <p>
        Die Zusammenarbeit mit unseren Auftraggebern sehen wir nicht als
        klassisches Dienstleistungsverhältnis, sondern als partnerschaftliches
        Miteinander, dass Sie und Ihre Apotheke nach vorne bringt.
      </p>
      <p>
        Unser Ziel ist es Ihre Apotheke langfristig und nachhaltig erfolgreicher
        zu machen. Wir wollen gemeinsam den Wandel der Zeit erkennen, damit sich
        Ihre Apotheke zeitgemäß weiterentwickeln kann.
      </p>
    </div>
    <div class="about-person mb-5">
      <b-card no-body class="overflow-hidden">
        <b-row no-gutters style="background-color: #dfdfdf">
          <b-col md="4">
            <b-img
              src="/assets/about/hans-eberhard-heppelmann.jpg"
              alt="Hans-Eberhard Heppelmann"
              class="rounded-0"
              fluid
            ></b-img>
          </b-col>
          <b-col md="8">
            <b-card-body title="Hans-Eberhard Heppelmann">
              <b-card-text class="description">
                Ausbildung zum Bankkaufmann, danach betriebswirtschaftliches
                Studium. Seit 1980 Erfahrung im pharmazeutischen Großhandel in
                verschiedenen Funktionen u. a. als Niederlassungsleiter und
                Regionalvertriebsleiter für 5 Niederlassungen. Durch diese
                Tätigkeit umfangreiche Erfahrung im Bereich Marketing. Bis 2021
                als kaufmännischer Leiter im Apothekenverbund der Ehefrau und
                hier zuständig für die Bereiche Finanzen und Controlling.
              </b-card-text>
            </b-card-body>
          </b-col>
        </b-row>
      </b-card>
    </div>
    <div class="about-person">
      <b-card no-body class="overflow-hidden">
        <b-row no-gutters style="background-color: #ffc6bd">
          <b-col md="8">
            <b-card-body title="Brigitte Wigand-Heppelmann">
              <b-card-text class="description">
                <ul>
                  <li>Apothekerin, Approbation 1973</li>
                  <li>
                    Bis 1985 angestellt in diversen Apotheken im Ruhrgebiet
                  </li>
                  <li>
                    Seit 1985 selbständig, Gründung des Stammhauses
                    Paracelsus-Apotheke
                  </li>
                  <li>
                    1998 Gründung eines Gesundheitszentrums, bestehend aus
                    Naturkostladen, Sanitätshaus und Kosmetikinstitut
                  </li>
                  <li>
                    Ab 2005 bis 2008 Gründung bzw. Übernahme weiterer Apotheken
                    als Filialen
                  </li>
                  <li>
                    In 2020 Standortbereinigung mit Schließung der
                    Nikolaus-Apotheke Hamm Uentrop. Nach 36-jähriger
                    Selbständigkeit Verkauf von 3 Apotheken
                    (Paracelsus-Apotheke, Löwen-Apotheke und
                    von-Kleist-Apotheke) im März 2021.
                  </li>
                  <li>
                    In 2021 Gründung der Apothekenberatungsgesellschaft AWH GmbH
                  </li>
                </ul>
              </b-card-text>
            </b-card-body>
          </b-col>
          <b-col md="4">
            <b-img
              src="/assets/about/brigitte-wigand-heppelmann.jpg"
              alt="Brigitte Wigand-Heppelmann"
              class="rounded-0"
              fluid
            ></b-img>
          </b-col>
        </b-row>
      </b-card>
    </div>
  </div>
</template>

<script>
export default {
  name: "Ueber",
  metaInfo: {
    title: "Über uns",
  },
};
</script>

<style scoped>
.header-img {
  max-height: 600px;
}
.description {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}
.about-person {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}
.about-person .description {
  text-align: left;
}
</style>
